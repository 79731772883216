import React from 'react';
import { Button } from '@stillfront/stillfront-component-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './TermsAndPrivacyPopUp.scss';

function TermsAndPrivacyPopUp() {
    const { t } = useTranslation();
    let navigate = useNavigate();

    // useEffect(() => {
    //     // Trigger the animation upon mounting and updating
    //     setActive(false); // Reset to trigger animation
    //     const timer = setTimeout(() => setActive(true), 5); // Short delay to trigger transition
    //     return () => clearTimeout(timer);
    // }, []); // this useEffect runs onMount

    const handleGoBack = () => {
        navigate('/consent');
    }

    return (
        <div className="auth-container">
            <div className='consent-details-container'>
                <h6>{t('termsConditionsTitle')}</h6>
                <p>{t('welcomeText')}</p>
                <strong>{t('useOfSiteSection')}</strong>
                <ul>
                    <li>{t('useOfSiteContent1')}</li>
                    <li>{t('useOfSiteContent2')}</li>
                    <li>{t('useOfSiteContent3')}</li>
                </ul>
                <strong>{t('productsServicesSection')}</strong>
                <ul>
                    <li>{t('productsServicesContent1')}</li>
                    <li>{t('productsServicesContent2')}</li>
                    <li>{t('productsServicesContent3')}</li>
                </ul>
                <strong>{t('paymentsTransactionsSection')}</strong>
                <ul>
                    <li>{t('paymentsTransactionsContent1')}</li>
                    <li>{t('paymentsTransactionsContent2')}</li>
                    <li>{t('paymentsTransactionsContent3')}</li>
                </ul>
                <strong>{t('privacyPolicy')}</strong>
                <ul>
                    <li>{t('privacyPolicyContent')}</li>
                </ul>
                <strong>{t('intellectualPropertySection')}</strong>
                <ul>
                    <li>{t('intellectualPropertyContent')}</li>
                </ul>
                <Button variant="contained" aria-label='go-back-privacy' className='privacy-button' onClick={handleGoBack}>{t('understandPrivacy')}</Button>
            </div>
        </div>
    );
}

export default TermsAndPrivacyPopUp;
