import React from 'react';
import { useLocation } from 'react-router-dom';
import { getPopupComponent } from '../../utils/contentMap';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/images/banner.png';
// import AuthModal from '../AuthContainer/AuthContainer';
// import AbortConfirmModal from '../AbortConfirmModal/AbortConfirmModal';
import ROUTES from '../../constants/routes';
import './AuthFlow.scss';
import AuthContainer from '../AuthContainer/AuthContainer';

function AuthFlow() {
    const location = useLocation();
    //const navigate = useNavigate();
    const { t } = useTranslation();

    /** TODO: remove unwanted code */
    //const [abortConfirmModalOpen, setAbortConfirmModalOpen] = useState(false);

    // const isAuthenticated = () => {
    //     return !!localStorage.getItem('csrfToken');
    // };

    // useEffect(() => {
    //     if (!isAuthenticated() && location.pathname !== ROUTES.SIGN_IN) {
    //         // If not authenticated and not already on the sign-in page, redirect to sign-in
    //         navigate(ROUTES.SIGN_IN);
    //     }
    // }, [location.pathname, navigate]);

    // const onHandleAbortModalClose = () => {
    //     setAbortConfirmModalOpen(false); // Hide abort confirmation
    // };

    const getContent = () => {
        const PopupComponent = getPopupComponent(location.pathname);
        return <PopupComponent />;
    };

    const headerTitle = t({
        //[ROUTES.SIGN_IN]: 'saveAccount',
        [ROUTES.VERIFY_EMAIL]: 'signInWith',
        [ROUTES.VERIFY_CODE]: 'signInWith',
        [ROUTES.CONSENT]: 'signInWith',
        [ROUTES.PRIVACY]: 'userAgreementTitle',
    }[location.pathname] || 'signInWith');

    // removing sign-in page from the options
    const showBanner = [ROUTES.VERIFY_EMAIL, ROUTES.VERIFY_CODE, ROUTES.CONSENT].includes(location.pathname);
    //const showLanguageSwitcher = [ROUTES.VERIFY_EMAIL].includes(location.pathname);

    return (
        <>
            <AuthContainer
                //showLanguageSwitcher={showLanguageSwitcher}
                headerTitle={headerTitle}
                showBanner={showBanner}
                bannerSrc={bannerImage}
                contentKey={location.pathname}
            >
                {getContent()}
            </AuthContainer>
            {/* This modal handles the abort registration process */}
            {/* {abortConfirmModalOpen && <div className="dimmed-modal-overlay"></div>}
            <AbortConfirmModal show={abortConfirmModalOpen} onHide={onHandleAbortModalClose} onCancel={onHandleAbortModalClose} /> */}
        </>

    );
}

export default AuthFlow;
